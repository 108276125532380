import _throwsOnProto from "./throws-on-proto";
var exports = {};
var call = Function.call;
var throwsOnProto = _throwsOnProto;
var disallowedProperties = [// ignore size because it throws from Map
"size", "caller", "callee", "arguments"]; // This branch is covered when tests are run with `--disable-proto=throw`,
// however we can test both branches at the same time, so this is ignored

/* istanbul ignore next */

if (throwsOnProto) {
  disallowedProperties.push("__proto__");
}

exports = function copyPrototypeMethods(prototype) {
  // eslint-disable-next-line @sinonjs/no-prototype-methods/no-prototype-methods
  return Object.getOwnPropertyNames(prototype).reduce(function (result, name) {
    if (disallowedProperties.indexOf(name) > -1) {
      return result;
    }

    if (typeof prototype[name] !== "function") {
      return result;
    }

    result[name] = call.bind(prototype[name]);
    return result;
  }, Object.create(null));
};

export default exports;